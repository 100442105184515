<template>
  <div>
    <v-row
      dense
      class="text-center justify-center"
      v-if="mocktest && mocktest.id"
    >
      <v-col cols="12" md="8">
        <v-card class="mb-2">
          <v-card-title>
            <v-btn icon @click="$router.go(-1)">
              <v-icon>mdi-arrow-left</v-icon></v-btn
            >
            <v-spacer></v-spacer>
            <span>
              {{ mocktest.title }}
            </span>
          </v-card-title>
        </v-card>
        <v-card class="mb-2">
          <v-card-actions>
            {{ $lang("Questions") }}
            <v-spacer></v-spacer>
            {{
              mocktest.segments.reduce((pre, cur) => {
                return pre + cur.__meta__.total_questions;
              }, 0)
            }}
          </v-card-actions>

          <v-card-actions>
            {{ $lang("Duration") }}
            <v-spacer></v-spacer>
            {{ calculateDuration(mocktest) }}
          </v-card-actions>
          
          <!-- 
          <v-card-actions v-if="mocktest.showDate">
            {{ $lang("Date") }}
            <v-spacer></v-spacer>
            {{ mocktest.updated_at | formatDateOnly }}
          </v-card-actions>
           -->
           
        </v-card>
        <v-card v-if="mocktest.description && mocktest.description.length > 10">
          <v-card-text class="title"> {{ $lang("DIRECTIONS") }}: </v-card-text>
          <v-divider></v-divider>
          <v-card-text v-html="mocktest.description" />
        </v-card>
        <v-card class="mt-2">
          <v-card-text class="text-left" v-html="directions"> </v-card-text>
        </v-card>
        <v-card class="mt-2">
          <purchase-dialog
            :mocktest="mocktest"
            v-if="mocktest.vcoin > 0 && !mocktest.owned"
            @success="() => { 
              $router.replace({ name: 'exams-mocktest-preview', params: { id: mocktest.id } });
            }"
          />
        </v-card>
        
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import bus from "@/bus";
import { axios } from "@/plugins/axios";
import moment from "@/plugins/moment";
import PurchaseDialog from "./PurchaseDialog";
export default {
  name: "exams-mocktest-preview",
  components: { PurchaseDialog },
  data() {
    return {
      mainBtn: {
        color: "green",
        text: "Start",
        time: null,
      },
      internal_directions: null,
      internal_disclaimer: null,
      agree: false,
      mocktest: null,
      pagination: {
        lastPage: null,
        page: null,
        perPage: null,
        total: null,
      },
    };
  },
  watch: {
    mocktest(val){
      console.log("watch", this.mocktest)
      setTimeout(() => {
        if(this.mocktest.owned){
          this.$router.replace({ name: 'exams-mocktest-preview', params: { id: this.mocktest.id } });
        }
      }, 600);
    }
  },
  computed: {
    ...mapGetters({
      auth: "auth/auth",
      attempt: "app/exams/attempt",
    }),
    disclaimer() {
      if (!this.internal_disclaimer || !this.mocktest) {
        return null;
      }
      let string = this.isHindi
        ? this.internal_disclaimer.value_hi
        : this.internal_disclaimer.value;

      let find = ["\n"];

      let replace = ["<br/>"];
      return string.replaceArray(find, replace);
    },
    directions() {
      if (!this.internal_directions || !this.mocktest) {
        return null;
      }
      let string = this.isHindi
        ? this.internal_directions.value_hi
        : this.internal_directions.value;

      let find = [
        "{{sections}}",
        "{{questions}}",
        "{{options}}",
        "{{duration}}",
        "{{positive}}",
        "{{negative}}",
        "\n",
      ];
      console.log("this.mocktest", this.mocktest);
      let replace = [
        this.mocktest.segments.length,
        this.mocktest.segments.reduce((pre, cur) => {
          return pre + cur.__meta__.total_questions;
        }, 0),
        "4",
        this.mocktest.duration.toString().fancyTime(),
        this.mocktest.points_correct,
        this.mocktest.points_incorrect,
        "<br/>",
      ];
      return string.replaceArray(find, replace);
    },
  },
  methods: {
    btnCalc() {
      if (this.mocktest && this.mocktest.elapsed) {
        let time = this.mocktest.duration - this.mocktest.elapsed;
        this.mainBtn = {
          color: "orange",
          text: "Start",
          time: time,
        };
      } else {
        this.mainBtn = {
          color: "green",
          text: "Start",
          time: 0,
        };
      }
    },
    calculateDuration(mocktest) {
      let duration = 0;
      if (mocktest.duration > 0) {
        duration = mocktest.duration;
      } else {
        duration = mocktest.segments.reduce((pre, cur) => {
          return pre + cur.duration;
        }, 0);
      }
      return duration.toString().fancyTime();
    },
    axiosMocktest() {
      return axios
        .get(
          `prep/mocktests/public/${this.$route.params.id}`
        )
        .then((res) => {
          this.mocktest = res.data;

          if(!res.data.vcoin || res.data.vcoin < 1) {
            this.$router.replace({ name: 'exams-mocktest-preview', params: { id: this.$route.params.id } });
          }

          return axios
            .get(
              `prep/mocktests/public/${this.$route.params.id}/questions?shuffle=1`
            ).then((res) => {
              this.$store.dispatch("app/exams/UpdateMocktests", res.data.data);
              this.$router.replace({ name: 'exams-mocktest-preview', params: { id: this.$route.params.id } });
            }).catch((err) => {
              console.error('Error->', err.response.data.message);

              if(err.response && err.response.status) {
                if(err.response.status == 200) {
                  return this.$router.replace({ name: 'exams-mocktest-preview', params: { id: this.$route.params.id } });
                }
                if ([500, 404, 402, 403].includes(err.response.status)) {
                  return this.$router.replace({ name: 'exams' });
                }
              }
            });

          
        })
        .catch((err) => {
          console.error('Error--->', err.response.data.message);
          // this.$router.go(-1);
        });
    },

    axiosGetMeta() {
      return axios
        .get(`globals/mocktest_directions`)
        .then((res) => {
          console.log(res);
          this.internal_directions = res.data;
          return axios.get(`globals/mocktest_disclaimer`).then((res) => {
            this.internal_disclaimer = res.data;
            return Promise.resolve(res.data);
          });
        })
        .catch((err) => console.error(err))
        .finally(() => {
          // bus.$emit("hideAppBar");
          // bus.$emit("hideBottomNav");
        });
    },
    saveAttemptRedirect(res) {
      return this.$store
        .dispatch("app/exams/SaveAttempt", res.data.data)
        .then(() => {
          this.$router.replace({
            name: "exams-attempt-result",
            params: { id: this.$route.params.id },
          });
        });
    },
    isDateSameOrAfter(value) {
      var value = moment(value, "YYYY-MM-DD hh:mm:ss").add(1, "d");
      var today = moment().format("YYYY-MM-DD hh:mm:ss");
      return moment(value).isSameOrAfter(today);
    },
  },
  created() {
    bus.$emit("showWait");
    this.loading = true;
    this.$store.dispatch("app/exams/resetMocktest");

    this.axiosGetMeta();
    let _id = this.$route.params.id;
    axios
      .get(`prep/attempts/attempted/${_id}`)
      .then((res) => {
        return this.saveAttemptRedirect(res);
      })
      .catch((err) => {
        // this test have not been attempted yet
        setTimeout(() => {

          this.axiosMocktest();
          
        }, 600);
      })
      .finally(() => {
        setTimeout(() => {
          this.btnCalc();
          this.loading = false;
          bus.$emit("hideWait");
        }, 1000);
      });
  },
};
</script>


