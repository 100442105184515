<template>
  <div class="text-center">
    <v-dialog v-model="dialog" persistent width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          text
          outlined
          color="red"
          class=""
          block
        >
          <v-icon left>mdi-lock</v-icon>
          {{ $lang("UNLOCKINCOINS", [mocktest.vcoin.toString()]) }}
        </v-btn>
      </template>

      <v-card v-if="authUser">
        <v-card-title class="grey darken-3 white--text mx-0 px-3">
          <div class="headerClass">
            {{ isHindi ? mocktest.title_hi : mocktest.title }}
          </div>
          <v-spacer></v-spacer>
          <v-btn :disabled="loading" dark icon @click="handleCancel">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text
          class="py-10"
          v-if="authUser.wallet.coins >= mocktest.vcoin"
        >
          You have
          <v-chip label small outlined
            >{{ authUser.wallet.vcoin }} vCoins</v-chip
          >
          and
          <v-chip label small outlined
            >{{ authUser.wallet.gcoin }} gCoins</v-chip
          >
          in your wallet. <br />
          <br />
          If you unlock this mocktest,
          <v-chip label small outlined>{{ mocktest.vcoin }} Coins </v-chip> will
          be deducted from your wallet. <br />
          <br />
          You will have
          <v-chip label small outlined
            >{{ authUser.wallet.coins - mocktest.vcoin }} Coins
          </v-chip>
          combined after that. <br />
          <br />
          Are you sure to continue ?
        </v-card-text>
        <v-card-text v-else class="py-10">
          You only have
          <v-chip label small outlined
            >{{ authUser.wallet.coins }} coins</v-chip
          >
          in your wallet, you will need an extra
          <v-chip label small outlined>
            {{ mocktest.vcoin - authUser.wallet.coins }} coins
          </v-chip>
          to unlock this test.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="authUser.wallet.coins >= mocktest.vcoin"
            :disabled="loading"
            :loading="loading"
            color="primary"
            block
            @click="handleUnlockClick"
          >
            {{ $lang("UNLOCKINCOINS", [mocktest.vcoin.toString()]) }}
          </v-btn>
          <v-btn
            v-else
            :disabled="loading"
            :loading="loading"
            color="orange darken-2 white--text"
            block
            @click="$router.push({ name: 'products' })"
          >
            {{ $lang("PURCHASEMOREVCOIN") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { axios } from "@/plugins/axios";
import bus from "@/bus";

export default {
  props: ["showDialog", "mocktest"],

  data() {
    return {
      loading: false,
      errors: null,
      dialog: this.showDialog,
    };
  },
  computed: {
    ...mapGetters({
      authUser: "auth/user",
    }),

    isHindi() {
      return this.$store.getters.getCurrLocale == "hi";
    },
  },
  watch: {
    showDialog: function () {
      this.dialog = this.showDialog;
    },
    dialog: function () {
      if (this.dialog == false) {
        this.$emit("hideDialog");
      }
    },
  },
  methods: {
    handleCancel() {
      this.dialog = false;
      this.errors = null;
      this.loading = false;
    },
    handleUnlockClick() {
      this.loading = true;
      bus.$emit("showWait");
      return axios
        .post("payments/unlock/mocktest/" + this.mocktest.id)
        .then((res) => {
          this.mocktest.owned = true;
          this.$emit("success", true);
          return this.$store.dispatch("auth/fetchUser");
        })
        .finally(() => {
          this.loading = false;
          this.dialog = false;
          bus.$emit("hideWait");
        });
    },
  },
};
</script>

<style scoped>
.headerClass {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80%;
}
</style>